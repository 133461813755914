<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import errorHandler from './../../../helpers/errorHandler.js';
import { required, integer } from 'vuelidate/lib/validators'

/**
 * Starter component
 */
export default {
  page: {
    title: "Balance Form",
  },
  data() {
    return {
      title: "Balance-create",
      items: [
        {
          text: "Work center",
          href: "/",
        },
        {
          text: "Balance codes",
          href: "/balance-codes",
        },
        {
          text: "Generate",
          active: true,
        },
      ],
      submit: false,
      type: 'create',
      form:{
        number: null,
        amount: null,
      },
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  validations:{
    form:{
      number:{
        required,
        integer
      },
      amount:{
        required,
        integer
      }
    }
  },
  methods:{
    handleSubmit() {
      this.submit = true;
      this.$v.$touch();
      if(!this.$v.$anyError ){
        // requests for the create
        this.$store.dispatch("balance/generate",{
          number: this.form.number,
          amount: this.form.amount,
        }).then(
           ()=>{
            this.$router.push('/balance-codes');
          }
        ).catch((err)=>{
          errorHandler.methods.error(err);
        });
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <div>
                <div>
                  <div>
                    <h2 v-if="type == 'create'">Generate Balance Codes</h2>
                    <hr>
                  </div>
                <form action="#" @submit.prevent="handleSubmit">
                <div class="row">
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-2"
                        label="Balance codes number to generate:"
                        label-for="input-D"
                    >
                        <input v-model="form.number" type="text" name="name" class="form-control" :class="{ 'is-invalid': submit && $v.form.number.$error }" placeholder="Enter number of balance codes" />
                        <div v-if="submit && $v.form.number.$error" class="invalid-feedback">
                            <span v-if="!$v.form.number.required">This field is required.</span>
                            <span v-if="!$v.form.number.integer">This value should be integer.</span>
                            
                        </div>
                    </b-form-group>
                    </div>
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-2"
                        label="Amount"
                        label-for="input-D"
                    >
                        <input v-model="form.amount" type="text" name="name" class="form-control" :class="{ 'is-invalid': submit && $v.form.amount.$error }" placeholder="Enter balance amount" />
                        <div v-if="submit && $v.form.amount.$error" class="invalid-feedback">
                            <span v-if="!$v.form.amount.required">This field is required.</span>
                            <span v-if="!$v.form.amount.integer">This value should be integer.</span>
                            
                        </div>
                    </b-form-group>
                    </div>
                </div>
                </form>
                </div>
                <hr />
                <div class="float-right">
                <button class="btn btn-outline-success" type="submit" @click="handleSubmit" v-if="type == 'create'">Generate</button>
                <b-button variant="outline-danger" class="ml-2 aline-right" @click="$router.go(-1)"
                >Cancel</b-button
                >
                </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
#input-group-1 {
  display: inline;
}
</style>